import moment from "moment";



export const addColumnWeeksGenerator = function(number, ordersData, off_days_number = 0, mps_view = 'week') {

    let newNumber
    if(mps_view === 'week'){
        newNumber = number
    } else if(mps_view === 'day'){
        newNumber = number - off_days_number -1
    }
    

  let ordersDataCopy = JSON.parse(JSON.stringify(ordersData));
  let object_keys = Object.keys(ordersDataCopy)
  let num_of_current_col = ordersDataCopy[object_keys[0]].columnOrder.length + 1;

  for (let i = 0; i < object_keys.length; i++) {
    //loop the orders array
    for (let n = 0; n < newNumber; n++) {
      //push number of times

      let num = num_of_current_col + n;

      let column_num = "column-" + num;
      let week_number = "week-" + num;
      let column_number = "column-" + num;

      ordersDataCopy[object_keys[i]].columns[column_number] = {
        id: column_number,
        title: week_number,
        processId: []
      };

      ordersDataCopy[object_keys[i]].columnOrder.push(column_num);

      
    }
  }
  return ordersDataCopy;
};

// export default demo_orders_arr;
