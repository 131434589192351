import {
  SET_CSV_WEEK_HEADERS,
  SET_CSV_WEEK_DATA,
  SET_CSV_MONTHLY,
  SET_CSV_CUSTOMER
} from "./types";
import { polyfill } from "es6-promise";
import moment from "moment";
polyfill();

export const setCsvWeekHeaders = weeks => async dispatch => {
  //***** Sample structure: *****



  let headers_array = [];
  let week_title = moment(weeks[0].date).format("w") + " שבוע";
  headers_array.push({ label: week_title, key: "y" }); //week number

  for (let i = 0; i < weeks.length; i++) {
    if (weeks[i].offDay === false) {
      headers_array.push({
        label: weeks[i].dateName + " - " + weeks[i].parsedDate,
        key: "details." + [i] + ""
      });
    }
  }
  let week_headers_and_title = { headers: headers_array, title: week_title };

  dispatch({
    type: SET_CSV_WEEK_HEADERS,
    payload: week_headers_and_title
  });
};

export const setCsvWeekDate = data => async dispatch => {
  //***** Sample structure: *****



  let process_data = [];
  let data_copy = JSON.parse(JSON.stringify(data));

  for (let i = 0; i < data_copy.length; i++) {
    for (let p = 0; p < data_copy[i].processes.length; p++) {
      if (data_copy[i].processes[p].length > 0) {
    
        for (let d = 0; d < data_copy[i].processes[p].length; d++) {
          let day_process = data_copy[i].processes[p];

          for (let pindex = 0; pindex < day_process.length; pindex++) {
            if (!day_process[pindex].backlog) {
              let employee = "אין עובד";

              if (!day_process[pindex].employee) {
                employee = "אין עובד";
              } else {
                employee = day_process[pindex].employee.name;
              }

              process_data.push({
                details: {
                  [p]: [
                    day_process[pindex].order_number,
                    "\n" + day_process[pindex].client_name,
                    "\n" + employee,
                    "\n" +
                      day_process[pindex].finished +
                      "/" +
                      day_process[pindex].quantity
                  ]
                },
                y: data_copy[i].process_name
              });
            }
          }
        }
      }
    }
  }

  let week_data = process_data;
  dispatch({
    type: SET_CSV_WEEK_DATA,
    payload: week_data
  });
};

export const setCsvMonthly = month_data => async dispatch => {
  let all_data = JSON.parse(JSON.stringify(month_data));
  //***** Sample structure: *****


  let headers_array = [];
  let month_title =
    all_data[0].parsedDate +
    " - " +
    all_data[all_data.length - 1].parsedDate +
    " :חודש"; // title!!!!!!!
  let week_processes = [];
  let empty_line = { details: { 0: " " }, y: "   " };

  // i = 0 , 1 , 2 , 3 , 4......

  //adding the headers
  //first week in the csv
  let first_week_num = moment(all_data[0].date).format("w") + " שבוע מס";
  headers_array.push({ label: first_week_num, key: "y" }); //week number
  for (let i = 0; i < 5; i++) {
    headers_array.push({
      label: all_data[i].dateName + " - " + all_data[i].parsedDate,
      key: "details." + [i] + ""
    });
  }

  dataGeneratrator(all_data, week_processes);

  let copy1_all_data = JSON.parse(JSON.stringify(all_data));

  //**** if more than 1 week ****
  if (all_data.length > 6) {
    week_processes.push(empty_line);
    copy1_all_data.splice(0, 7);
    newWeekGeneratator(copy1_all_data, week_processes);
  }

  let copy2_all_data = JSON.parse(JSON.stringify(copy1_all_data));

  //**** if  more than 2 week ****
  if (copy1_all_data.length > 6) {
    week_processes.push(empty_line);
    copy2_all_data.splice(0, 7);
    newWeekGeneratator(copy2_all_data, week_processes);
  }
  let copy3_all_data = JSON.parse(JSON.stringify(copy2_all_data));

  //**** if  more than 3 week ****
  if (copy2_all_data.length > 6) {
    week_processes.push(empty_line);

    copy3_all_data.splice(0, 7);

    newWeekGeneratator(copy3_all_data, week_processes);
  }

  let monthyl_headers_data_title = {
    headers: headers_array,
    data: week_processes,
    title: month_title
  };

  dispatch({
    type: SET_CSV_MONTHLY,
    payload: monthyl_headers_data_title
  });
};


const newWeekGeneratator = (new_date_array, week_processes) => {
//   need to push new "headers" for the new week { details: { a:  "ראשון",b:  "שני", c:  "שלישי", d:  "רביעי", e:  "חמישי"}, y: 'שבוע  51'},
  let new_week_line_obj = {
    details: {},
    y: moment(new_date_array[0].date).format("w") + " שבוע מס"
  };

  for (let i = 0; i < 5; i++) {
    new_week_line_obj.details[i] =
      new_date_array[i].dateName + " - " + new_date_array[i].parsedDate;
  }

  week_processes.push(new_week_line_obj);
  dataGeneratrator(new_date_array, week_processes);
};


const dataGeneratrator = (all_data, week_processes) => {
  for (let i = 0; i < 5; i++) {
    for (let p = 0; p < all_data[i].dayData.length; p++) {

      let day_process = all_data[i].dayData[p]; 

      if (!day_process.proccess.backlog) {
        let employee = "אין עובד";

        if (!day_process.proccess.employee) {
          employee = "אין עובד";
        } else {
          employee = day_process.proccess.employee.name;
        }

        week_processes.push({
          details: {
            [i]: [
              day_process.order_number,
              "\n" + day_process.client_name,
              "\n" + employee,
              "\n" +
                day_process.proccess.finished +
                "/" +
                day_process.proccess.quantity
            ]
          },
          y: day_process.proccess.process_name
        });
      }
    }
  }

  return week_processes;
};

export const setCsvCustomer = customer_Data => {
  let all_data = JSON.parse(JSON.stringify(customer_Data));
  let factory = JSON.parse(localStorage.getItem('LOGIN_DATA')).result.factory_name;

  let headers_array = ['כתובת','עִיר','אימייל','טלפון','שֵׁם','ם איש קשר','מספר לקוח'].map((item, index) => {
    return {
      label: item,
      key: "details." + [index]
    }
  });
  let customer_title = 'customer'
  let customer_data = all_data.map(customer => {
    const { _id, contact_name, name, phone, email, customer_number, city, address } = customer
    return{
      details: {
        [0]: address,
        [1]: city,
        [2]: email,
        [3]: phone,
        [4]: name,
        [5]: contact_name,
        [6]: customer_number,
      }
    }
  });

  let customer_headers_data_title = {
    headers: headers_array,
    data: customer_data,
    title: `${factory}_${customer_title}_${new Date().toISOString()}`
  };

  return{
    type: SET_CSV_CUSTOMER,
    payload: customer_headers_data_title
  };
};
