import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from '@material-ui/core/Tooltip';
import ChangeSystemPopUp from "../PopUps/ChangeSystemPopUp/ChangeSystemPopUp";
import useComponentVisible from "../../../hooks/useComponentVisible/useComponentVisible";
import "./Table.scss"
import SelectInputForTable from "../InputFields/selectInputForTable/SelectInputForTable";


export default function BasicTable(props) {
    const { popUpData, columns, rows, actionClickHandler } = props.data;
    const { view, popupClickHandlers, tableFor } = props;
    const [rowData, setRowData] = useState(rows);
    const [orderDirection, setOrderDirection] = useState("asc");
    const [showPopUpById, setShowPopUpById] = useState(null);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);


    const StyledTableRow = withStyles((theme) => ({
        root: {
            borderBottom: "none",
            '&:nth-of-type(even)': {
                backgroundColor: '#F9F9F9',
            },
            "&:hover": {
                backgroundColor: '#F9F9F9',
            },
        },
    }))(TableRow);

    const StyledTableCell = withStyles({
        root: {
            borderBottom: "none",
            padding: '0px 5px',   // .MuiTableCell-root
        },
    })(TableCell);

    const CustomTooltip = withStyles({
        tooltip: {
            color: "#ffffff",
            backgroundColor: "#6C7389",
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16.8px',
            padding: '10px 12px 10px 12px',
            width: '394px',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '8px',
            fontFamily: 'Rubik !important',
        }
    })(Tooltip);

    const useStyles = makeStyles((theme) => ({
        headerCell: {
            //fontSize: view === 'tablet' ? '24px !important' : '14px',
            fontSize: view ===  '14px',
            fontWeight: 800,
            color: '#455768',
            // direction: 'ltr',
            fontFamily: 'Rubik !important',
            padding: '10px 5px'
        },
        tableCell: {
            color: view === 'tablet' ? '#6C7389' : '#455768',
            // fontSize: view === 'tablet' ? '24px' : '14px',
            fontSize: '14px',
            fontFamily: 'Rubik !important',
            fontWeight: 400,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // direction: 'ltr',
            position: 'relative',
            maxWidth: view === 'tablet' ? '192px' : '100px',
            // borderTop: view === ('tablet' && columns.length > 7) && '1px solid #CACBCC',
            // borderBottom: view === ('tablet' && columns.length > 7) && '1px solid #CACBCC',
            textAlign: 'justify',
        },
        tableCellPadding: {
            paddingTop: '15px',
            paddingBottom: '15px'
        },
        iconContainer: {
            width: '32px',
            height: '30px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        filesName: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        headName: {
            maxWidth: view === 'tablet' ? '150px' : '100px',
            textOverflow: 'wrap',
            color: '#6C7389',  //'#455768',
        },
        tableContainerStyle: {
            maxHeight: tableFor === 'paymentDetails' ? "100vh" : "calc(100vh - 350px)",
            // maxHeight: '500px',
            marginTop: '5px',
            overflowY: 'scroll'
        },
        table: {
            // border: ('tablet' && columns.length > 7) && 'none',
            // borderCollapse: ('tablet' && columns.length > 7) && 'separate',
            // borderSpacing: ('tablet' && columns.length > 7) && '0px 10px'
        },
    }));

    const classes = useStyles();

    const sortArray = (arr, orderBy, field) => {
        switch (orderBy) {
            case "asc":
            default:
                const data = arr.sort((a, b) => {
                    return a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
                }
                );
                return data;
            case "desc":
                return arr.sort((a, b) =>
                    a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0
                );
        }
    };

    const handleSortRequest = (field) => {
        setRowData(sortArray(rows, orderDirection, field));
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    };

    useEffect(() => {
        setRowData(rows); // Update rowData when rows changes
      }, [rows]);


    const handleOpenSystemPopUp = (rowId, rowIdx) => {
        if (showPopUpById === rowId) {
         
            setShowPopUpById(null);
        } else if (rowId) {
            setShowPopUpById(rowId);
          
        } 
        // setIsComponentVisible(!isComponentVisible);
        
    }


    return (
        <>
            <TableContainer className={classes.tableContainerStyle} component={Paper}>
                <Table aria-label="simple table" className={classes.table} style={{
                    // border: ('tablet' && columns.length > 7) && 'none',
                    // borderCollapse: ('tablet' && columns.length > 7) && 'separate',
                    // borderSpacing: ('tablet' && columns.length > 7) && '0px 10px'
                }}
                stickyHeader
                >
                    <TableHead style={{ background: '#EEEEEE' }}>
                        <TableRow style={{ maxHeight: '52px' }}>
                            {
                                columns.map((col, index) => (
                                    col.headerName === 'Actions' ?
                                        <StyledTableCell
                                            key={index}
                                            className={classes.headerCell}
                                             align="right"
                                        >
                                            {/* {col.headerName} */}
                                            <span className={classes.headName}> {col.headerName} </span>
                                        </StyledTableCell>
                                        : col.headerName === 'Description' && view === 'tablet' ?
                                            <StyledTableCell key={index} className={classes.headerCell} align="right">
                                                {col.headerName}
                                            </StyledTableCell>
                                           // : col.headerName === 'Description' ? null
                                                : <StyledTableCell
                                                    key={index}
                                                    className={classes.headerCell}
                                                    onClick={() => handleSortRequest(col.field)}
                                                    style={{ textAlign: view === 'tablet' && col.field === 'uploadedBy' ? 'right' : 'right' }}

                                                >
                                                    {
                                                        view === 'tablet' ? <span className={classes.headName}> {col.headerName} </span>
                                                            : <TableSortLabel active={true} direction={orderDirection}>
                                                                <span className={classes.headName}> {col.headerName} </span>
                                                            </TableSortLabel>
                                                    }

                                                </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData? rowData.map((row, rowIdx) => {                        
                            return (
                                <>

                                    <StyledTableRow key={row.id} className="tableRown" style={{ textAlign: 'center', overflow: 'visible' }}>
                                        {
                                            columns && columns.map((col, colIdx) => {                       
                                                if (row[col.field]) {
                                                    if (col.field === 'fileName') {
                                                        return <StyledTableCell key={colIdx} align="right" className={`${classes.tableCell} ${classes.tableCellPadding}`} style={{ maxWidth: view === 'tablet' ? '772px' : '394px' }}>
                                                            <CustomTooltip title={row.fileName} aria-label="add" arrow>
                                                                <span className={classes.filesName}>{row && row[col.field]}</span>
                                                            </CustomTooltip>
                                                        </StyledTableCell>
                                                    }
                                                     else if (col.field === 'description' && view === 'tablet') {
                                                        return <StyledTableCell key={colIdx} align="right" className={classes.tableCell}>{row && row[col.field]}</StyledTableCell>
                                                    }  else if ((col.field === 'fileType') && view === 'tablet') {
                                                        return (<StyledTableCell key={colIdx} className={classes.tableCell}
                                                            style={{ display: 'flex', justifyContent: 'right', maxWidth: view === 'tablet' && '300px' }}
                                                        >
                                                            <SelectInputForTable data={row && row[col.field]} />
                                                            {/* <SelectInput data={row && row[col.field]} /> */}
                                                        </StyledTableCell>)
                                                    } else if (col.field === 'percent') {
                                                        return (<StyledTableCell key={colIdx} className={classes.tableCell}
                                                            style={{ display: 'flex', justifyContent: 'right', maxWidth: view === 'tablet' && '300px'}}
                                                        >
                                                            <SelectInputForTable data={row && row[col.field]}/>
                                                            {/* <SelectInput data={{...row[col.field], forTable: true}} /> */}
                                                        </StyledTableCell>)
                                                    } else if ((col.field === 'fileType' || col.field === 'percent')) {
                                                        return (<StyledTableCell key={colIdx} className={classes.tableCell}
                                                            style={{ justifyContent: 'right', maxWidth: view === 'tablet' && '300px' }}
                                                        >
                                                            <SelectInputForTable data={row && row[col.field]} />
                                                        </StyledTableCell>)
                                                    } 
                                   
                                                    else if (col.field === 'uploadedBy' && view === 'tablet') {
                                                       
                                                        return (<StyledTableCell key={colIdx} className={classes.tableCell}>
                                                            <SelectInputForTable data={row && row[col.field]} />
                                                        </StyledTableCell>)
                                                    }
                                                    else if ((col.field === 'resourceName' || col.field === 'status') && (view === 'tablet' || view === 'desktop')) {
                                                       
                                                        return (<StyledTableCell key={colIdx} className={classes.tableCell}>
                                                            <SelectInputForTable data={row && row[col.field]} />
                                                        </StyledTableCell>)
                                                    }
                                                    
                                                    
                                                    return <StyledTableCell key={colIdx} align="right" style={{ borderRight: (view === 'tablet' && colIdx === columns.length) && '1px solid #CACBCC' }} className={`${classes.tableCell} ${classes.tableCellPadding}`}>{row && row[col.field]}</StyledTableCell>
                                                }
                                            })
                                        }
                                        {columns[columns.length - 1].field === 'Actions' &&
                                            <StyledTableCell
                                                align="center"
                                                ref={ref}
                                                onClick={() => handleOpenSystemPopUp(row.id, rowIdx)}
                                                style={{ position: 'relative', display: 'flex', top: '15px', justifyContent: 'left' }}
                                            >
                                                <div style={{ visibility: (showPopUpById && showPopUpById === row.id) ? 'visible' : 'hidden' }}>
                                                        <ChangeSystemPopUp
                                                            data={popUpData && popUpData}
                                                            id={row.id}
                                                            onClickHandler={actionClickHandler}
                                                            clickHandlers={popupClickHandlers}
                                                        />
                                                </div>
                                                <span className={classes.iconContainer} style={{ background: '#F7F7F7', }}>
                                                    <MoreVertIcon style={{ color: '#006DC0' }} />
                                                </span>
                                            </StyledTableCell>
                                        }
                                    </StyledTableRow>

                                </>
                            )
                        }) : <p className="table__noItem">there is no data</p>}
                    </TableBody>
                </Table>
            </TableContainer>

        </>

    );
}