//daily view 
import { Grid } from '@material-ui/core'
import React, { useState } from 'react';
import "./SystemsTab.scss";
import Button from '../../Buttons/Button';
import SystemsList from './SystemsList/SystemsList';
import AddSystemPopUp from '../PopUps/AddSystem/AddSystemPopUp';
import DeletePopUp from '../PopUps/Delete/DeletePopUp';
import {get} from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { systemActionsCreator } from '../../../../actions/customer/orderProcessSystemActions';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { TABLET_VIEW_TRANSLATION } from '../../../../constants/translations/customer/tablet/tabletView';
import AddMeasurements from '../../MobileViews/PopUps/AddMeasurements/AddMeasurements';
import CheckListTable from './Table/CheckListTable';

import { systemTabBtnsTablet } from "./SystemsTabStatic";

export default function SystemsTab(props) {
    const { showPopUp, handleShowPopUp, closePopup, view, process, selected_process, add_from, } = props;
    const [currentPopup, setCurrentPopup] = useState('add')
    const [currentLayer, setCurrentLayer] = useState(1);
    const [systemId, setSystemId] = useState(null);
    const [parentSystemId, setParentSystemId] = useState(null);
    const [selectedParentSystem, setSelectedParentSystem] = useState(null);

    const orderId = process?.order_id || process?.orderId;

    const dispatch = useDispatch();
    const systemsTableData = useSelector((state) => state.orderProcessSystemReducer.systemsCheckListTablet);
    const totalProgress = useSelector((state) => state.orderProcessSystemReducer.totalProgress);
    const checkList = useSelector((state) => {
        const data = get(state.orderProcessSystemReducer.systemsForAccordion, state.orderProcessSystemReducer.accordionPath)
        return data?.checkList || {}
    });
    const selectedSystem = useSelector((state) => state.orderProcessSystemReducer.singleSystem);


    function onClickHandler(label) {
        setCurrentPopup(label);
        handleShowPopUp();
    }

    const popupClickHandlers = {
        'Add System': (systemId, layer) => {
            setCurrentPopup('add');
            if (add_from && add_from == "layer0") {
                setCurrentLayer(1)
            } else {
                setCurrentLayer(layer+ 1)
            }
            setParentSystemId(systemId)
            handleShowPopUp();
            setParentSystemId(systemId)
        },
        'Edit System': (systemId, layer) => {
            setCurrentPopup('edit');
            setCurrentLayer(layer);
            setSystemId(systemId);
            handleShowPopUp();
            dispatch(systemActionsCreator.selectedSystemBySystemId({ systemId, layer }));
        },
        'Delete System': (systemId, layer) => {
            setCurrentPopup('delete');
            handleShowPopUp();
            setCurrentLayer(layer);
            setSystemId(systemId);
            if (systemId && layer) {
                dispatch(systemActionsCreator.selectedSystemBySystemId({ systemId, layer }))
            }
        },
        'Add Measurements': () => {
            setCurrentPopup('measurement');
            handleShowPopUp();
        },
    }

    const systemNameToDelete = selectedSystem.system_name;
    const locationDescriptionToDelete = selectedSystem.location_description;
    const floorToDelete = selectedSystem.location_floor;

    const deleteInfoPopUp = {
        title: POP_UPS_TRANSLATION.DELETE_SYSTEM,
        subtitle: POP_UPS_TRANSLATION.DELETE_SYSTEM_CONFIRMATION,
        parentSystemId: selectedSystem.parent_system_id,
        info: [
            { label: POP_UPS_TRANSLATION.SYSTEM_NAME, value: systemNameToDelete && (typeof (systemNameToDelete) === 'object' ? systemNameToDelete.name : systemNameToDelete) },
            { label: POP_UPS_TRANSLATION.LOCATION_DESCRIPTION, value: locationDescriptionToDelete && locationDescriptionToDelete },
            { label: POP_UPS_TRANSLATION.FLOOR, value: floorToDelete && floorToDelete },
        ]
    }

    return (
        <Grid container spacing={0} >
            <Grid className='system-tab-main'>
                <Grid item xs={6} md={4} className='system-tab-main__right'>
                    <SystemsList popupClickHandlers={popupClickHandlers} onClickHandler={onClickHandler} process={process} setSelectedParentSystem={setSelectedParentSystem} selectedParentSystem={selectedParentSystem} />
                </Grid>
                <Grid item xs={6} md={8} className='system-tab-main__left'>
                    <h4 className='system-tab-main__title'>{TABLET_VIEW_TRANSLATION.CHECK_LIST}</h4>
                    <div className='checkListTableData'>
                           <CheckListTable totalProgress={totalProgress} data={systemsTableData} columns={systemsTableData.columns} checkListId={checkList?._id} rows={checkList.data || []} orderId={orderId} process={selected_process} parentSystemId={selectedParentSystem} /> 
                    </div>
                </Grid>
            </Grid>

            <div className={showPopUp ? 'popUp__background' : ""}>
                {
                    showPopUp ?
                        <>
                            <div style={{ visibility: currentPopup && currentPopup === 'delete' ? 'visible' : 'hidden' }}>
                                <DeletePopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    data={deleteInfoPopUp}
                                    view={view}
                                    id={systemId}
                                    layer={currentLayer}
                                    popUpFor={'system'}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'edit' ? 'visible' : 'hidden' }}>
                                <AddSystemPopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    name={POP_UPS_TRANSLATION.EDIT_SYSTEM}
                                    view={view}
                                    mode={'edit'}
                                    setParentSystemId={setParentSystemId}
                                    parentSystemId={parentSystemId}
                                    from="b"
                                    customerId={process.customerId}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'add' ? 'visible' : 'hidden' }}>
                                <AddSystemPopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    name={POP_UPS_TRANSLATION.ADD_A_SYSTEM}
                                    view={view}
                                    layer={currentLayer}
                                    parentSystemId={parentSystemId}
                                    setParentSystemId={setParentSystemId}
                                    selected_process={selected_process}
                                    from="a"
                                    customerId={process.customerId}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'measurement' ? 'visible' : 'hidden' }}>
                                <AddMeasurements showPopUp={showPopUp} handleShowPopUp={handleShowPopUp} view={view === 'mobile' ? view : 'desktop'} />
                            </div>
                        </> : null
                }
            </div>

            <Grid item xs={12} className='fileTab__btnRow'>
                <Grid item xs={6} className='fileTab__btnRow-right'>
                    <Button data={systemTabBtnsTablet.save} />
                    &nbsp;
                    &nbsp;
                    <Button data={systemTabBtnsTablet.cancel} handleClick={() => closePopup()}/>
                </Grid>
                <Grid item xs={6} className='fileTab__btnRow-left'>

                    <Button data={systemTabBtnsTablet.startTimeCount} />
                    &nbsp;
                    &nbsp;
                    <Button
                        data={systemTabBtnsTablet.system}
                        handleClick={() => {
                            setCurrentPopup('add');
                            setCurrentLayer(1);
                            handleShowPopUp()
                        }}
                    />
                </Grid>

            </Grid>
        </Grid>
    )
}
