import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

export default function MonthViewNew() {
    const [arrayLen, setArrayLen] = useState(new Array(20).fill(0));
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loading, setLoading] = useState(false)

    const loadMoreFunction = () => {
        setLoading(true)
        if (arrayLen.length > 150){
            setLoading(false)
            setHasMoreData(false)
        }else{
            setTimeout(() => {
                setArrayLen(() => new Array(arrayLen.length + 10).fill(1));
                setLoading(false)
            }, 500);
        }
    }
  return (
    <div style={{height: '700px', width: '100%', overflow: 'auto', border: '4px solid red'}}>
          <InfiniteScroll
              pageStart={1}
              loadMore={loadMoreFunction}
              hasMore={hasMoreData}
              loader={loading && <div className="loader" key={0}>Loading ...</div>}
              useWindow={false}
          >
           {
                arrayLen.map((item, index)=> {
                return <div key={index} style={{width: '200px', height: '200px', border: '1px solid blue', marginBottom: '5px'}}>{index}</div>
                  })
           }
          </InfiniteScroll>
    </div>
  )
}
