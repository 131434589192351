import { ADD_TASK_TO_TASKS_LIST_MOBILE, ADD_TASK_TO_TASKS_LIST_TABLET, DELETE_TASK_BY_TASK_ID, EDIT_SELECTED_TASK_BY_TASK_ID, GET_TASKS_LIST_MOBILE, GET_TASKS_LIST_TABLET, SELECTED_TASK_BY_TASK_ID, SET_TASKS_TO_TASKS_LIST_TABLET } from "../../constants/customer/taskConstants";
import { mobileTasksList, tabletTasksList, } from "../../constants/staticeData/tasksList";


const initialState = {
    tasksMobile: mobileTasksList,
    tasksTablet: tabletTasksList,
    singleTask: {},
}

export default function taskReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TASKS_LIST_MOBILE:
            return { ...state, tasksMobile: state.tasksMobile };
            break;
        case GET_TASKS_LIST_TABLET:
            return { ...state, tasksTablet: state.tasksTablet };
            break;
        case ADD_TASK_TO_TASKS_LIST_TABLET:
            const newRow = { 
                id: action.payload.id, 
                fileName: action.payload.taskName, 
                resourceName: {placeholder: action.payload.resourceName.name}, 
                dueDate: action.payload.dueDate, 
                status: { placeholder: action.payload.status.name, selectionOptions: ["png", "jpeg", "pdf", "text"] } 
            }
            const newRowMobile = { 
                id: action.payload.id, 
                fileName: action.payload.taskName, 
                resourceName: action.payload.resourceName.name, 
                dueDate: action.payload.dueDate, 
                status: action.payload.status.name, 
            }
            return { ...state, tasksTablet: { ...state.tasksTablet, rows: [...state.tasksTablet.rows, newRow] }, tasksMobile: { ...state.tasksMobile, rows: [...state.tasksMobile.rows, newRowMobile] } };
            break;
        // case ADD_TASK_TO_TASKS_LIST_MOBILE:
        //     const newRow = { id: 1, fileName: action.payload.taskName, resourceName: { label: '', placeholder: action.payload.resourceName.name, width: '100px', height: '30px', border: 'none' }, dueDate: action.payload.dueDate, fileType: { label: '', placeholder: action.payload.status.name, width: '100px', height: '30px', border: 'none' } }
        //     return { ...state, tasksTablet: { ...state.tasksTablet, rows: [...state.tasksTablet.rows, newRow] } };
        //     break;
        case SELECTED_TASK_BY_TASK_ID:
            const task = state.tasksTablet.rows.filter((row) => row.id == action.payload);
            return { ...state, singleTask: { ...state.singleTask, ...task[0] } };
            break;
        case DELETE_TASK_BY_TASK_ID:
            const remainingTask = state.tasksTablet.rows.filter((row) => row.id !== action.payload);
            const remainingTaskMobile = state.tasksMobile.rows.filter((row) => row.id !== action.payload);
            return { ...state, tasksTablet: { ...state.tasksTablet, rows: [...remainingTask] }, tasksMobile: { ...state.tasksMobile, rows: [...remainingTaskMobile] } };
            break;
        case EDIT_SELECTED_TASK_BY_TASK_ID:
            const otherRows = state.tasksTablet.rows.filter((row) => row.id !== action.payload.id);
            const editedTaskRow = { 
                id: action.payload.id, 
                fileName: action.payload.taskName, 
                resourceName: {placeholder: action.payload.resourceName.name || action.payload.resourceName.placeholder}, 
                dueDate: action.payload.dueDate, 
                status: { placeholder: action.payload.status.name || action.payload.status.placeholder, selectionOptions: ["png", "jpeg", "pdf", "text"] } 
            }
            const otherRowsMobile = state.tasksMobile.rows.filter((row) => row.id !== action.payload.id);
            const editedTaskRowMobile = { 
                id: action.payload.id, 
                fileName: action.payload.taskName, 
                resourceName: action.payload.resourceName.name || action.payload.resourceName.placeholder, 
                dueDate: action.payload.dueDate, 
                status: action.payload.status.name || action.payload.status.placeholder,
            }

            const data = { ...state, tasksTablet: { ...state.tasksTablet, rows: [...otherRows, editedTaskRow] }, tasksMobile: { ...state.tasksMobile, rows: [...otherRowsMobile, editedTaskRowMobile] } };
            return data;
            break;
        case SET_TASKS_TO_TASKS_LIST_TABLET:
            const newRowsForTablet = action.payload && action.payload.map((task, index) => {
                return {
                    id: index,
                    fileName: task.description,
                    dueDate: task.due_date,
                    fileType: task.is_done,
                };
            });
            return {
                ...state, 
                tasksTablet: { ...state.tasksTablet, rows: [ ...newRowsForTablet], }, 
                tasksMobile: { ...state.tasksMobile, rows: [ ...newRowsForTablet]}
            };
        default:
            return state;
    }
}