import {useEffect} from 'react'
import { useScrollDirection } from 'react-use-scroll-direction'

export const useCalendarScroll = (element, addItemsCb) => {
    const {
        isScrollingLeft
    } = useScrollDirection(element?.current)
    const scrollWidth = element?.current?.scrollWidth
    const clientWidth = element?.current?.clientWidth
    const scrolledLeft = element?.current?.scrollLeft

    const scrolledToEnd = scrollWidth === (clientWidth + (-scrolledLeft))

    useEffect(() => {
        (scrolledToEnd) && addItemsCb(true)
    }, [scrolledToEnd, , isScrollingLeft])


}