import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProcesses, getOrders } from "../../../actions/actions";

import "../../../sass/adminPanel/_admin.scss";
import TableOrder from "./TableOrder";

export const OrderList = () => {
  const dispatch = useDispatch();

   const orderlist = useSelector(
    (state) => state.adminOrders.listOrders
  );

  const filterFactory = useSelector((state) => state.factories.filterFactory);





  return (
    <div className = "admin_page">     
     in developing
      {/* <TableOrder orderlist = {orderlist}></TableOrder> */}
    </div>
  );
};
