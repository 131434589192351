import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

// red  -> x -> 0 -> bar color red
// green -> x > 0 -> bar color green
// grey -> no change

function CircularProgressWithLabel({ value, color , onClick, height}) {
  let background = color;
  const pValue = value == 0 ? 2 : value;

  const colorMap = {
    red: "red",
    green: "green",
  };
  const pColor = colorMap[background] || "#0091ff";
  return (
    <div onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default', display: "flex", justifyContent: 'center', alignItems: 'center', height, position: "relative" }}>
      <CircularProgress
        variant="determinate"
        value={pValue}
        color={pColor}
        thickness={5}
        size={height}
        style={{
          position: "absolute",
          top: 0,
          color: color,
          zIndex: 1,
          margin: "0px",
          padding: "0px",
          transformOrigin: "center",
        }}
      />

      <CircularProgress
        variant="determinate"
        thickness={5}
        value={100}
        size={height}
        style={{
          position: "absolute",
          top: 0,
          margin: "0px",
          padding: "0px",
          transformOrigin: "center",
          opacity: 0.99,
          color: "#efefef",
        }}
      />
      <label style={{ fontSize: height/4, color: color}}>
        {value ? `${Math.round(value)}%` : "0%"}
      </label>
    </div>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  diameter: PropTypes.number,
};

export default function CircularWithValueLabel(props) {
  const { data, progress_color } = props;
  return <CircularProgressWithLabel value={data || 0} color={progress_color} onClick={props.onClick} height={props.diameter || 35}/>;
}
