import * as types from "./types";
import axios from "axios";
import { api, createHeaders } from "../constants/api-urls";

export const updateIsPaymentReceivedOrder = (data) => {
    return (dispatch) => {
      dispatch(updateIsPaymentReceivedOrderRequest());
      let url = `${api.user.updateCollectionStagesInFinancialReport.updatePaymentReceived}`;
      const headers = createHeaders();
      axios
        .post(url, data, { headers })
        .then((res) => {
          const { ok, result } = res.data;
          dispatch(updateIsPaymentReceivedOrderSuccess(result));
        })
        .catch((err) => {
          dispatch(updateIsPaymentReceivedOrderFailure(err.message));
        });
    };
  };
  
  const updateIsPaymentReceivedOrderRequest = () => ({
    type: types.ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_REQUEST,
  });
  
  const updateIsPaymentReceivedOrderSuccess = (result) => ({
    type: types.ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_SUCCESS,
    payload:result
  });
  
  const updateIsPaymentReceivedOrderFailure = (error) => ({
    type: types.ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_ERROR,
    payload: {
      error,
    },
  });

  export const updateInvoiceOrder = (data) => {
    return (dispatch) => {
      dispatch(updateInvoiceOrderRequest());
      let url = `${api.user.updateCollectionStagesInFinancialReport.updateInvoiceOrder}`;
      const headers = createHeaders();
      axios
        .post(url, data, { headers })
        .then((res) => {
          const { ok, result } = res.data;
          dispatch(updateInvoiceOrderSuccess(result));
        //   dispatch(getOrders(filterFactory));
        })
        .catch((err) => {
          dispatch(updateInvoiceOrderFailure(err.message));
        });
    };
  };
  
  const updateInvoiceOrderRequest = () => ({
    type: types.ORDER_UPDATE_STAGE_INVOICE_REQUEST,
  });
  
  const updateInvoiceOrderSuccess = (result) => ({
    type: types.ORDER_UPDATE_STAGE_INVOICE_SUCCESS,
    payload:result
  });
  
  const updateInvoiceOrderFailure = (error) => ({
    type: types.ORDER_UPDATE_STAGE_INVOICE_ERROR,
    payload: {
      error,
    },
  });