import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import reduxThunk from "redux-thunk";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import history from './services/history';
import reducers from "./reducers/reducers";
import { createLogger } from 'redux-logger';
import "./sass/main/index.scss";

import { polyfill } from "es6-promise";
import App from "../src/App";
import {refreshPageAfterAwake} from './functions/general/refreshPageAfterAwake'
polyfill();
refreshPageAfterAwake()


export const store = createStore(
  reducers(history),
  {},
  compose(
    applyMiddleware(
      routerMiddleware(history),
      reduxThunk,
      ...(process.env.NODE_ENV !== 'production' ? [createLogger()] : [])
    )
  )
);



ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BrowserRouter history={history}>
        <App />
      </BrowserRouter>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);


serviceWorker.register();
