import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react';
import "./AccordionRow.scss";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ChangeSystemPopUp from '../../PopUps/ChangeSystemPopUp/ChangeSystemPopUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularWithValueLabel from '../../Progress/CircularProgress/CircularProgress';
import useComponentVisible from '../../../../hooks/useComponentVisible/useComponentVisible';
import CheckListPopUp from '../../PopUps/CheckListPopUp/CheckListPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { systemActionsCreator } from '../../../../actions/customer/systemActions';


const popUpDataMobileTablet = [
  {
    label: "Show Details",
    icon: 'vision'
  },
  {
    label: "Add System",
    icon: 'add'
  },
  {
    label: "Edit System",
    icon: 'edit'
  },
  {
    label: "Delete System",
    icon: 'delete'
  },
  {
    label: "Add Measurements",
    icon: 'add'
  },
  {
    label: "Move Up",
    icon: 'up'
  },
  {
    label: "Move Down",
    icon: 'down'
  },
];

const popUpDataDesktop = [
  {
    label: "Add System",
    icon: 'add'
  },
  {
    label: "Edit System",
    icon: 'edit'
  },
  {
    label: "Delete System",
    icon: 'delete'
  },
  {
    label: "Add Measurements",
    icon: 'add'
  },
  {
    label: "Move Up",
    icon: 'up'
  },
  {
    label: "Move Down",
    icon: 'down'
  },
]

function getBoxShadowByLayer(layer){
  const layer1BoxShadow = 'rgba(0, 0, 0, 0) 0px 0px 0px';
  const layer2BoxShadow = 'rgba(62, 176, 167, 0.4) 3px 0px, rgba(62, 176, 167, 0.3) 4px 0px, rgba(62, 176, 167, 0.2) 5px 0px, rgba(62, 176, 167, 0.1) 6px 0px, rgba(62, 176, 167, 0.05) 7px 0px';
  const layer3BoxShadow = 'rgba(189, 22, 87, 0.4) 3px 0px, rgba(189, 22, 87, 0.3) 4px 0px, rgba(189, 22, 87, 0.2) 5px 0px, rgba(189, 22, 87, 0.1) 6px 0px, rgba(189, 22, 87, 0.05) 7px 0px';
  const layer4BoxShadow = 'rgba(62, 84, 176, 0.4) 3px 0px, rgba(62, 84, 176, 0.3) 4px 0px, rgba(62, 84, 176, 0.2) 5px 0px, rgba(62, 84, 176, 0.1) 6px 0px, rgba(62, 84, 176, 0.05) 7px 0px';
  const layer5BoxShadow = 'rgba(197, 50, 196, 0.4) 3px 0px, rgba(197, 50, 196, 0.3) 4px 0px, rgba(197, 50, 196, 0.2) 5px 0px, rgba(197, 50, 196, 0.1) 6px 0px, rgba(197, 50, 196, 0.05) 7px 0px';

  let boxShadow;
  switch (layer) {
    case 1: 
      boxShadow = layer1BoxShadow;
      break;
    case 2:
      boxShadow = layer2BoxShadow;
      break;
    case 3:
      boxShadow = layer3BoxShadow;
      break;
    case 4:
      boxShadow = layer4BoxShadow;
      break;
    case 5:
      boxShadow = layer5BoxShadow;
      break;
    default:
      boxShadow = layer1BoxShadow;
      break;
  }

  return boxShadow;
}

function parsSystemName(details){
  if (typeof (details) === 'object' && details.name){
    return details.name;
  } else if (details){
    return details;
  }else {
    return <span>&nbsp;</span>
  }
  // typeof (details && details) === 'object' ? details && details.name : details && details
}

export default function AccordionRow(props) {
  const { view, isIcon, onClickHandler, popupClickHandlers, rowIndex, showNext, path,
    setSelectedParentSystem
   } = props;
  const { items: data, systemId, layer, originalSystemId } = props.data;
  const [expandAccordion, setExpandAccordion] = useState(false);
  const [isViewAllDetails, setIsViewAllDetails] = useState(false);
  const [showCheckList, setShowCheckList] = useState(false);

  const systemsOpeningPath = useSelector((state) => state.customerSystems.systemsOpeningPath);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const dispatch = useDispatch();
  const systemsTableData = useSelector((state) => state.customerSystems.systemsCheckListTablet);

  function addNextChildSystemToPath(toActive){
    const dataToSend = {
      systemName: data[0]?.details,
      layer,
      isActive: toActive ? true : false
    };
    if (systemsOpeningPath.length > 0 && systemsOpeningPath[systemsOpeningPath.length - 1].layer === layer){
      removeAllNextChildSystemOfPath(layer)
    }
    if (systemsOpeningPath.length > 0 && systemsOpeningPath[systemsOpeningPath.length - 1].layer > layer) {
      // if last system, of existing path layer is getter then currently open path, that means new system has been opened,
      // remove all the system from path till they did not match to the currently open system layer
      let removeSystemTillCurrentLayer = layer
      removeAllNextChildSystemOfPath(removeSystemTillCurrentLayer)
    }
 
    dispatch(systemActionsCreator.setSystemsOpeningPath(dataToSend))
  }

  function removeAllNextChildSystemOfPath(layer){
    dispatch(systemActionsCreator.removeSystemFromOpeningPath(layer))
  }

  function handleShowNextLayer(e) {
    e.stopPropagation()
    setExpandAccordion(!expandAccordion)
    showNext(!expandAccordion)
   
    if (expandAccordion){
      removeAllNextChildSystemOfPath(layer)
    }else{
      addNextChildSystemToPath(expandAccordion);
    }
  }

  function handleOpenSystemPopUp() {
    setIsComponentVisible(!isComponentVisible);
  }

  function handleViewAllDetails() {
    setIsViewAllDetails(!isViewAllDetails);
  }

  function remainingDetailsForMobile() {
    const remainingRowData = data && [...data.slice(1, data.length)]
    return remainingRowData
  }

  function handleOpenChecklistPopup() {
    const getCheckListBy = {
      systemId: systemId,
      parentSystemId: systemId,
      layer: layer,
      processId: props.process?.processId,
      path,
    }
    dispatch(systemActionsCreator.getCheckListBySystemId(getCheckListBy))
    if (systemsTableData.rows) {
      setShowCheckList(true);
    }
  }

  const nameColor = data && data[0]?.nameColor;
  const nameBackground = data && data[0]?.nameBackground;
  const name = data && data[0]?.name;
  const details = data && data[0]?.details;

  return (
    <>
      <Grid container
        spacing={0}
        className={`${view === 'tablet' ? 'accordionRow__main--tablet' : 'accordionRow__main'}`}
        style={{
          boxShadow: (view === 'desktop' || view === 'tablet') && getBoxShadowByLayer(layer),
          height: view === 'mobile' ? (isViewAllDetails && '96px') : (isViewAllDetails && '100px'),
          // height: view === 'mobile' ? (isViewAllDetails && '96px') : (isViewAllDetails && '188px'),
          //  height:  isViewAllDetails && view == 'tablet' ? '188px' :'96px',
          backgroundColor: isViewAllDetails ?  '' : '#ffffff',
        }}
        onClick={(e) => {
          e.stopPropagation(); 
          handleOpenChecklistPopup();
          addNextChildSystemToPath(true);
          if (setSelectedParentSystem) {
            setSelectedParentSystem(props.data.parentId);
          } else {
          }
       }}
      >
        <Grid className={`${view === 'tablet' ? 'accordion__col-1--tablet accordionRow__col--tablet' : 'accordion__iconContainer__mobile  accordion__col-1 accordionRow__col'}`} style={{ top: isViewAllDetails && '40px' }}>
          {
            isIcon && <div
              className={`${view === 'tablet' ? 'accordion__icons_container--tablet' : 'accordion__icons_container'}`}
              onClick={(e) => handleShowNextLayer(e)}
              style={{
                background: isViewAllDetails ? (expandAccordion && view !== 'tablet' ? '#F2F7FB' : '#FFFFFF') : '#F2F7FB'
              }}
            >
              {
                expandAccordion ? <RemoveIcon className={`${view === 'tablet' ? 'accordion__icon--tablet' : 'accordion__icon'}`} /> : <AddIcon className={`${view === 'tablet' ? 'accordion__icon--tablet' : 'accordion__icon'}`} />
              }
            </div>
          }
        </Grid>

        <Grid className={`${view === 'tablet' ? 'accordionRow__col--tablet accordion__col-4--tablet' : 'accordionRow__col accordion__col-4'}`}>
          <div className='accordionRow__col__content'>
            <Typography className={`${view === 'tablet' ? 'accordionRow__col__name--tablet' : 'accordionRow__col__name'}`}><span style={{ color: nameColor && nameColor, background: nameBackground && nameBackground }}>{name && name}</span></Typography>
            <span 
            className={`${view === 'tablet' ? 'accordionRow__col__text--tablet' : 'accordionRow__col__text'}`}
            >
              {parsSystemName(details)}
            </span>
          </div>
        </Grid>

        {
          data && data.length > 1 && data.slice(1, data.length).map((item, index) => {
            return <Grid key={index} style={{ display: (view === 'tablet' || view === 'mobile') && 'none' }} className='accordionRow__col  accordion__col-3'>
            {/* className be need to aadd on upper line desktopView */}
              <div className='accordionRow__col__content'>
                <Typography className='accordionRow__col__name'><span style={{ color: item.nameColor, background: item.nameBackground }}>{item.name}</span></Typography>
                <span className='accordionRow__col__text'>{item.details ? item.details : <span>&nbsp;</span>}</span>
              </div>
            </Grid>
          })
        }

        <Grid className={`${view === 'tablet' ? 'accordion__col-2--tablet' : 'accordion__col-2 accordion__progress--alignment'}`}>
          <Grid className={`${view === 'tablet' ? 'accordionRow__menuProgress--tablet' : 'accordionRow__menuProgress'} ${view === 'mobile' && isViewAllDetails ? 'accordion__progress--mobileViewExpand' : ''}`}>
            <Grid className={`${view === 'tablet' ? 'accordion__menuIcon_container--tablet' : 'accordion__menuIcon_container'}`}
              ref={ref}
              style={{
                // background: isViewAllDetails && (expandAccordion && view !== 'tablet' ? '#F2F7FB' : '#FFFFFF')
                background: isViewAllDetails ? (expandAccordion && view !== 'tablet' ? '#F2F7FB' : '#FFFFFF') : '#F2F7FB'
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenSystemPopUp(e)
                if (setSelectedParentSystem) {
                  setSelectedParentSystem(props.data.parentId);
                } 
              }}>
              {
                isComponentVisible ? <ChangeSystemPopUp
                  id={originalSystemId && originalSystemId}
                  layer={layer}
                  data={view === 'desktop' ? popUpDataDesktop : popUpDataMobileTablet}
                  handleViewAllDetails={handleViewAllDetails}
                  view={props.view}
                  onClickHandler={onClickHandler}
                  clickHandlers={popupClickHandlers}
                  rowIndex={rowIndex}
                /> : null
              }
              <MoreVertIcon className='accordion__menuIcon__icon' />
            </Grid>
          </Grid>
        </Grid>


        {/* for the viewAllDetails in mobile view*/}
        {
          isViewAllDetails ? <>
            {
              remainingDetailsForMobile().length > 0 && remainingDetailsForMobile().map((item, index) => {
                return <Grid key={index} className={`${view === 'tablet' ? 'accordionRow__col accordion__col-5--tablet accordionRow__viewAllDetails--tablet' : 'accordionRow__col accordion__col-5 accordionRow__viewAllDetails'}`}>
                  <div className='accordionRow__col__content'>
                    <Typography className={`${view === 'tablet' ? 'accordionRow__col__name--tablet' : 'accordionRow__col__name'}`}><span style={{ color: item.nameColor, background: item.nameBackground }}>{item.name}</span></Typography>
                    <span className={`${view === 'tablet' ? 'accordionRow__col__text--tablet' : 'accordionRow__col__text'}`}>{item.details}</span>
                  </div>
                  <>{index === 0 && <>&nbsp;</>}</>
                </Grid>
              })
            }
          </> : null
        }


      </Grid>

    </>

  )
}
