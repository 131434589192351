import React, { Component } from "react";
import moment from "moment";
//components
import ReportsPage from "./Parts/ReportsPage";
import ReportsSideNav from "./Parts/ReportsSideNav";
import Loader from "../LoaderNew/Loader";
//funcs
import {
  getReport,
  generateReport,
  isExistReport,
} from "../../functions/api/orders";
import SideBar from "../SideBar/SideBar";

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      startingDate: moment().startOf("month"),
      endingDate: moment().endOf("month"),
      timestamp: {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
      },
      // planned: 0,
      // forecast: 0,
      // exitedCounter: 0,
      // prevCounter: 0,
      // delayedCounter: 0,
      // total: 0,
      dataArray: [],
      loader: false,
      isGenerated: false,
      csvData: [],
      changeToNewDates: true,
    };
  }

  async componentDidMount() {
    this.generateReport();
  }

  generateReport = async () => {
    let startingDate = moment(this.state.timestamp.from);
    let endingDate = moment(this.state.timestamp.to);
    this.setState({isGenerated:false})
    let res = await generateReport(
      startingDate._d.toISOString(),
      endingDate._d.toISOString()
    );
    if (res.ok) {
      this.setState({
        startingDate,
        endingDate,
        changeToNewDates: true,
        isGenerated: true,
      });
      this.createDataForPage(res);
    }
  };

  createDataForPage = (data) => {
    if(data.result && data.result.length){
      const dataForReportPage = data.result[0]
      this.setState(
        {
          isGenerated: true,
          total_actual:dataForReportPage.total_actual,
          total_planned:dataForReportPage.total_planned,
          // report:[...dataForReportPage.report],
          loader: false,
          dataArray:[...dataForReportPage.report]
        },
        () => {
          this.initializedCsvData();
        }
      );
    };
    }

  displayNewData = async () => {
    let startingDate = moment(this.state.timestamp.from);
    let endingDate = moment(this.state.timestamp.to);
    this.setState(
      {
        startingDate,
        endingDate,
      },
      async () => {
        this.setState({ loader: true });
        let res = await generateReport(
          startingDate._d.toISOString(),
          endingDate._d.toISOString()
        )
        if (res.ok && res.result.is_done) {
          this.createDataForPage(res.result.report);
        } else {
          this.setState({ loader: false });
        }
      }
    );
    this.setState({
      changeToNewDates: true,
    });
  };

  checkExist = async () => {
    let res = await isExistReport();
    return res;
  };

  resetChangeToNewDates = () => {
    this.setState({
      changeToNewDates: false,
    });
  };

  setStartDate = (date) => {
    let startingDate = moment(date);
    this.setState({ startingDate });
  };

  setEndDate = (date) => {
    let endingDate = moment(date);
    this.setState({ endingDate });
  };

  setRange = (timestamp) => {
    this.setState({ timestamp });
  };

  updateDataArray = (new_data_array) => {
        this.setState(
          {
            dataArray: new_data_array,
          },
          () => {
            this.initializedCsvData();
          }
        );
      };

  updateTotalActualValue = (value) => {
    this.setState({total_actual:value},() => this.initializedCsvData())
  }

  initializedCsvData = () => {
    let data = JSON.parse(JSON.stringify(this.state));
    let csvData = [
      [
        "דו”ח פיננסי לחודש",
        // " ",
        `${moment(data.startingDate).format("DD/MM/YYYY")} - ${moment(
          data.endingDate
        ).format("DD/MM/YYYY")}`,
      ],
      [],
      [
        "סה”כ מתוכנן:",
         // " ",
        `${data.total_planned ? data.total_planned : 0}`
      ],
      [
        "יצא:",
         // " ",
        `${data.total_actual ? data.total_actual : 0}`,
      ],
      [],
      ["שם לקוח", "שלב", "מתוכנן","לחיוב","חשבונית", "קבלה", "יצא"],
    ];

    data.dataArray.map((item) => {
        csvData.push([
          item.customer_name
            ? item.customer_name  
            : "-",
          item.process ? item.process : "-",
          item.planned ? item.planned : 0,
          item.billable ? '    v':'',
          item.invoice_issued ? '    v':'',
          item.payment_received ? '    v' : '',
          item.actual ? item.actual : 0
        ]);
    });

    this.setState({ csvData });
  };

  render() {
    const {
      startingDate,
      endingDate,
      loader,
      csvData,
      changeToNewDates,
      timestamp,
      isGenerated,
    } = this.state;
    let reportsPageData = JSON.parse(JSON.stringify(this.state));

    return (
      <div className="reports__container">
        <SideBar />

        <div className="reports-page">
          {loader ? <Loader /> : null}
          <ReportsSideNav
            setStartDate={this.setStartDate}
            setEndDate={this.setEndDate}
            setRange={this.setRange}
            generateReport={this.generateReport}
            csvData={csvData}
            timestamp={timestamp}
            isGenerated={isGenerated}
          />
         {/* {this.props.reportsPageData && this.props.reportsPageData.report &&  */}
         <ReportsPage
            resetChangeToNewDates={this.resetChangeToNewDates}
            changeToNewDates={changeToNewDates}
            updateDataArray={this.updateDataArray}
            startingDate={startingDate}
            endingDate={endingDate}
            reportsPageData={reportsPageData}
            updateTotalActualValue = {this.updateTotalActualValue}
            isGenerated = {isGenerated}
          />
          {/* } */}
        </div>
      </div>
    );
  }
}

export default Reports;